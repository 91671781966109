* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background: #c9d2e3;
  min-height: 100vh;
}

button {
  cursor: pointer;
  border: 0;
}

a {
  text-decoration: none;
}