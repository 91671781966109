.admin-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 6px 24px 6px;
}

.label {
    color: #000;
    font-weight: medium;
    margin-top: 4px;
    margin-bottom: 2px;
}

.container-colors {
    display: flex;
    margin: 14px 0;
    gap: 22px;
}

.right {
    margin-right: 14px;
}

.btn-register {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-register svg {
    margin-right: 8px;
}

.title {
    color: #657eae;
    margin: 28px 0 14px 0;
}

.list {
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(36, 36, 36, 0.35);
    border-radius: 4px;
    padding: 14px 8px;
    margin-bottom: 14px;
}

.list-text {
    color: #657eae;
    font-weight: bold;
}

.groupcheck {
    display: flex;
    align-items: center;
    flex-direction: row;
    vertical-align: text-top;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer button {
    height: 36px;
    border-radius: 4px;
    background-color: #003768;
    border: 0;
    font-size: 18px;
    padding: 10px;
    color: #fff;
    margin-right: 10px;
}

.btn-delete {
    border: 1.5px dashed #FFF;
    color: #FFF;
    background-color: #000;
    padding: 4px 8px;
    border-radius: 2px;
}

.head-form {
    width: 90%;
    max-width: 600px;
}

.btn-close {
    color: #FFF;
    background-color: #003768;
    padding: 4px;
    border-radius: 5px;
}

.btn-view {
    /* border: 1.5px dashed #FFF; */
    color: #657eae;
    padding: 4px 8px;

}

.btn-view:hover {
    /* border: 1.5px dashed #FFF; */
    color: green;

}

.animate-pop {
    animation-duration: 0.5s;
    animation-name: animate-pop;
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.7, 0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}