.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 600px;
}

.form button {
    height: 36px;
    border-radius: 4px;
    background-color: #003768;
    border: 0;
    font-size: 18px;
    color: #fff;
}

.form hr {
    color: #fff;
}