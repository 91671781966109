.admin-header {
    width: 100%;
    max-width: 720px;
    margin-top: 14px;
}

.nav-header {
    width: 100%;
    background-color: #FFF;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-header button {
    border: 0;
    background-color: transparent;
    margin-right: 24px;
    margin-left: 8px;
}

.nav-header a {
    margin-right: 14px;
    color: #21242d;
    transition: color 0.2s;
}

.nav-header a:hover {
    color: #eda617;
}

.shadow {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15) !important;
}