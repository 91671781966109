.logo {
    margin-top: 10px;
    margin-bottom: 28px;
    color: #fff;
    font-size: 54px;
}

.logo-text {
    background: linear-gradient(#E18700, #FFD43a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 460px) {
    .logo {
        font-size: 50px;
    }
}

@media screen and (max-width: 400px) {
    .logo {
        font-size: 45px;
    }
}

@media screen and (max-width: 360px) {
    .logo {
        font-size: 40px;
    }
}